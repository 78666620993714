import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogIndex = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata?.title || `Title`
    const posts = data.allMarkdownRemark.nodes

    return (
        <Layout location={location} title={siteTitle}>
            <SEO title="List of all comics" />
            <ol style={{ listStyle: `none` }}>
                {posts.map((post, index) => {
                    const title = post.frontmatter.title || post.fields.slug

                    return (
                        <li key={post.fields.slug}>
                            <article
                                className="post-list-item"
                                itemScope
                                itemType="http://schema.org/Article"
                            >
                                <span>
                                    <Link to={post.fields.slug} itemProp="url">
                                        <h3
                                            style={{ display: "inline" }}
                                            itemProp="headline"
                                        >
                                            {index + 1 + ". " + title}
                                        </h3>
                                    </Link>
                                    <small
                                        style={{
                                            paddingLeft: "15px",
                                            textAlign: "right",
                                        }}
                                    >
                                        {post.frontmatter.date}
                                    </small>{" "}
                                </span>
                                <p>{post.frontmatter.description || ""}</p>
                            </article>
                        </li>
                    )
                })}
            </ol>
        </Layout>
    )
}

export default BlogIndex

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: ASC }) {
            nodes {
                fields {
                    slug
                }
                frontmatter {
                    date(formatString: "MMMM DD, YYYY")
                    title
                    description
                }
            }
        }
    }
`
